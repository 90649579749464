<template>
  <div class="sampledata">
    <a-form :form="form" layout="inline">
      <a-row>
        <a-form-item label="样本名称">
          <a-input placeholder="样本名称" v-decorator="['name']"/>
        </a-form-item>
        <a-form-item label="检测方式">
          <a-input placeholder="检测方式" v-decorator="['mode']"/>
        </a-form-item>
        <a-form-item label="样本接收人员">
          <a-input placeholder="样本接收人员" v-decorator="['receiver']"/>
        </a-form-item>
      </a-row>
      <a-row>
        <a-form-item label="被检单位">
          <a-input placeholder="被检单位" v-decorator="['orign']"/>
        </a-form-item>
        <a-form-item label="检测结果">
          <a-select style="width: 174px" v-decorator="['check']">
            <a-select-option value="合格">
              合格
            </a-select-option>
            <a-select-option value="不合格">
              不合格
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="检测时间">
          <a-range-picker
              v-decorator="['range_time', rangeConfig]"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
          />
        </a-form-item>

        <a-button type="primary" @click="query">查询</a-button>
        <a-button style="margin-left: 12px" @click="resetForm">重置</a-button>
      </a-row>
    </a-form>

    <div style="margin-top: 30px; margin-bottom: 8px; font-size: 14px">
      共有<span style="color: red">{{ pagination.total }}</span
    >条数据
    </div>
    <a-table
        style="background-color: #fff"
        rowKey="id"
        bordered
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleTableChange"
    >
      <template slot="mode" slot-scope="text, record">
        <editable-cell
            :text="text"
            @change="onModeCellChange(record, 'mode', $event)"
        />
      </template>
      <template slot="receiver" slot-scope="text, record">
        <editable-cell
            :text="text"
            @change="onCellChange(record, 'receiver', $event)"
        />
      </template>
    </a-table>
  </div>
</template>

<script>
import {listSample} from "@/api/sample";
import EditableCell from "./EditableCell";
import axios from "@/utils/axios";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "追溯码",
    dataIndex: "code",
    key: "code",
  },
  {
    title: "样本名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "检测方式",
    dataIndex: "mode",
    key: "mode",
    scopedSlots: {customRender: 'mode'}
  },
  {
    title: "样本接收人员",
    dataIndex: "receiver",
    key: "receiver",
    scopedSlots: {customRender: "receiver"},
  },
  {
    title: "被检单位",
    dataIndex: "orign",
    key: "orign",
  },
  {
    title: "检测结果",
    dataIndex: "check",
    key: "check",
  },
  {
    title: "检测时间",
    dataIndex: "create_time",
    key: "create_time",
  },
];
export default {
  name: "SampleData",
  components: {EditableCell},
  data() {
    return {
      data: [],
      columns,
      showModal: false,
      form: this.$form.createForm(this, {name: "sample_data"}),
      rangeConfig: {
        rules: [{type: 'array', required: false}],
      },
      queryItems: {},
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10,
      },
    };
  },
  methods: {
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.loadData(this.pagination);
    },

    loadData() {
      listSample(this.pagination.current, this.pagination.pageSize, this.queryItems).then(
          (res) => {
            this.pagination.total = res.data.total;
            this.data = res.data.rows || [];
          }
      );
    },

    onCellChange(record, dataIndex, value) {
      axios
          .post("/sample/receiver/update", {id: record.id, receiver: value})
          .then(() => {
            this.loadData();
          });
    },
    onModeCellChange(record, dataIndex, value) {
      axios
          .post("/sample/mode/update", {id: record.id, mode: value})
          .then(() => {
            this.loadData();
          });
    },
    resetForm() {
      this.form.resetFields()
      this.queryItems = {}
    },
    query() {
      this.form.validateFields((err, fieldsValue) => {
        if (!err) {
          const rangeTimeValue = fieldsValue['range_time'];
          this.queryItems = {
            ...fieldsValue,
            'range_time': [
              rangeTimeValue && rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss') || '',
              rangeTimeValue && rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss') || '',
            ],
          }

          this.loadData()
        }
      });
    }
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>